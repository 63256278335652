<template>
  <div>
    <div v-show="showInformations" class="wrapper">
      <div class="wrapper__card">
        <div class="wrapper__card__header">
          <div class="left">
            <h3 style="font-weight: 300 !important">
              Registro de falhas de envios
            </h3>
          </div>
          <div class="right d-flex justify-end">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :close-on-click="false"
              :nudge-width="200"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <z-btn
                  icon="$filter_alt"
                  v-bind="attrs"
                  v-on="on"
                  text="Filtros"
                  primary
                  color="primary"
                />
              </template>

              <v-card width="500" class="pa-5">
                <div class="mb-6">
                  <small>
                    <strong>Escolha abaixo os filtros desejados:</strong></small
                  >
                </div>

                <v-form>
                  <v-row dense>
                    <!-- <v-col cols="12" class="mb-2">
                      <DatePicker
                        v-model="pickerDate"
                        range
                        value-type="YYYY-MM-DD"
                        format="DD/MM/YYYY"
                        style="width: 100% !important"
                      />
                    </v-col> -->

                    <!-- <v-col cols="12" class="mb-2">
                      <z-autocomplete
                        label="Nome"
                        type="text"
                        :hideDetails="true"
                        :items="[1, 2, 3, 4, 5]"
                      />
                    </v-col> -->

                    <!-- <v-col cols="12" class="mb-2">
                      <z-autocomplete
                        v-model="journeyStepIdTest"
                        label="journeyStepId"
                        type="text"
                        :hideDetails="true"
                        :items="this.errorDetailsList.map((d) => d.journeyStepId)"
                      />
                    </v-col> -->

                    <v-col cols="12" class="mb-2">
                      <z-autocomplete
                        v-model="reasonFilter"
                        label="Motivo do erro"
                        type="text"
                        :hideDetails="true"
                        :items="this.errorDetailsList.map((d) => d.reason)"
                      />
                    </v-col>

                    <v-col cols="12" class="mb-2">
                      <z-autocomplete
                        v-model="filterType"
                        label="Tipo"
                        itemText="text"
                        itemValue="value"
                        :hideDetails="true"
                        :items="errorTypes"
                      />
                    </v-col>

                    <v-col cols="12" class="mb-2">
                      <z-input
                        v-model="filterEmail"
                        label="E-mail"
                        type="text"
                        :hideDetails="true"
                        :items="this.errorDetailsList.map((d) => d.email)"
                      />
                    </v-col>

                    <v-col cols="12" class="mb-2">
                      <z-input
                        v-model="filterNumber"
                        label="Telefone"
                        type="text"
                        :hideDetails="true"
                      />
                    </v-col>
                  </v-row>
                </v-form>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div
                    class="d-flex align-center"
                    style="justify-content: space-between; width: 100%"
                  >
                    <div class="left">
                      <z-btn
                        v-if="hasSomeFilter"
                        text="Resetar"
                        primary
                        color="#D33246"
                        style="color: white"
                        @click="resetFilters"
                      />
                    </div>

                    <div class="right d-flex align-center" style="gap: 0.5rem">
                      <z-btn
                        text="Cancelar"
                        primary
                        color="primary"
                        @click="menu = false"
                      />
                      <z-btn text="Filtrar" @click="setFilter" />
                    </div>
                  </div>
                </v-card-actions>
              </v-card>
            </v-menu>
            <z-btn
              v-if="errorDetailsList.length"
              icon=" $save"
              text="Download"
              primary
              color="primary"
              class="ml-2"
              :is-loading="loadingDownload"
              @click="downloadDocumentTemplates"
            />
          </div>
        </div>
        <div class="wrapper__card__body">
          <v-card flat height="100%">
            <z-pagination
              :auto-pagination="false"
              :current-page="currentPage"
              :number-of-pages="numberOfPages"
              icon-previous="mdi-chevron-left"
              icon-next="mdi-chevron-right"
              :number-of-items="errorDetailsList.length"
              :items="errorDetailsList"
              @click:next="goNextPage"
              @click:previous="backPreviousPage"
              @change:page="pageChangegedBySelect"
            >
              <template #data="{ paginatedItems }">
                <div class="table-wrapper">
                  <z-table
                    class="table tableErrors"
                    :headers="headers"
                    :items="paginatedItems"
                    :sort-desc="sortDesc"
                    icon-next="mdi-chevron-right"
                    icon-previous="mdi-chevron-left"
                    @sort="sortTable"
                  >
                    <template #no-data>
                      <div class="no-data-table">
                        <h3>Sem dados disponíveis.</h3>
                      </div>
                    </template>

                    <template v-slot:[`item.created`]="{ item }">
                      <span>{{ formateDate(item.created) }} </span>
                    </template>

                    <template v-slot:[`item.value`]="{ item }">
                      <span
                        >{{ item.type === "mail" ? item.email : item.number }}
                      </span>
                    </template>

                    <template v-slot:[`item.number`]="{ item }">
                      <span>{{ item.number || "Sem informação" }} </span>
                    </template>

                    <template v-slot:[`item.type`]="{ item }">
                      <span>{{ messageTypes[item.type] }} </span>
                    </template>

                    <template v-slot:[`item.reason`]="{ item }">
                      <span>{{ getErrorReason(item.reason) }} </span>
                    </template>

                    <template v-slot:[`item.errorMessage`]="{ item }">
                      <span>{{ item.errorMessage || "Sem informação" }} </span>
                    </template>
                  </z-table>
                </div>
              </template>
            </z-pagination>
          </v-card>
        </div>
      </div>
    </div>
    <div v-show="!showInformations" class="wrapper no-data">
      <div class="wrapper__card">
        <!-- <div class="loader"></div> -->
        <z-loading />
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable no-undef -->
<script>
import moment from "moment";
import ApiClient from "@/services/ApiClient";
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
// import ZAccordion from "@/components/Shared/ZAccordion.vue";
export default {
  components: {
    // DatePicker,
  },
  data() {
    return {
      pickerDate: "",
      fav: true,
      menu: false,
      message: false,
      hints: true,
      sortDesc: false,
      items: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
        { id: 9 },
        { id: 10 },
      ],
      // headers: [
      //   // { text: "", value: "favorite" },
      //   { text: "Data de criação", value: "created", sortable: true },
      //   { text: "Nome", value: "name", sortable: true },
      //   { text: "E-mail", value: "email", sortable: true },
      //   { text: "Telefone", value: "phone", sortable: true },
      //   { text: "Template", value: "template" },
      //   { text: "Motivo", value: "motive" },
      // ],
      headers: [
        // { text: "", value: "favorite" },
        {
          text: "Data de criação",
          value: "created",
          sortable: false,
          align: "center",
        },
        {
          text: "E-mail/SMS",
          value: "value",
          sortable: false,
          align: "center",
        },
        { text: "Tipo", value: "type", align: "center" },
        { text: "Motivo", value: "reason", align: "center" },
        { text: "Mensagem de erro", value: "errorMessage", align: "center" },
      ],
      errorDetailsList: [],
      errorTypes: [
        { text: "SMS", value: "sms" },
        { text: "E-mail", value: "mail" },
      ],
      connectionToDelete: null,
      connectionToUpdate: null,
      loadingDelete: false,
      loadingRename: false,
      filtered: [],
      searchConnection: "",
      http: new ApiClient(),
      showAudienceModal: false,
      messageTypes: {
        mail: "E-mail",
        sms: "SMS",
      },
      stats: {
        pending: "Pendente",
        updated: "Atualizado",
        error: "Error",
        "in-progress": "Em progresso",
      },
      errorReasons: {
        duplicated: "Usuário duplicado na execução da jornada",
        optout: "Usuário com optout marcado",
      },
      recent: [],
      showInformations: false,
      appliedFilter: "",
      fetchIntervalFn: null,
      currentUrl: null,
      currentPage: 1,
      numberOfPages: 0,
      emailList: [],
      numberList: [],
      testArr: [],
      journeyStepIdTest: "",
      filterType: "",
      filterEmail: "",
      filterNumber: "",
      urlFiltered: "",
      reasonFilter: "",
      loadingDownload: false,
    };
  },
  watch: {},
  beforeDestroy() {
    if (this.fetchIntervalFn) {
      clearInterval(this.fetchIntervalFn);
    }
  },
  created() {
    this.getUserLogError();
  },
  computed: {
    hasSomeFilter() {
      let has_data =
        this.filterEmail ||
        this.filterNumber ||
        this.filterType ||
        this.reasonFilter;
      return has_data?.length > 0;
    },
  },
  methods: {
    getErrorReason(reason) {
      return this.errorReasons[reason] || reason;
    },
    resetFilters() {
      this.filterEmail = "";
      this.filterNumber = "";
      this.filterType = "";
      this.urlFiltered = "";
      this.reasonFilter = "";
      this.menu = false;
      this.getUserLogError();
    },
    setFilter() {
      let URL = "";

      if (this.filterEmail) {
        URL += `&email=${this.filterEmail}`;
      }

      if (this.filterNumber) {
        URL += `&number=${this.filterNumber}`;
      }

      if (this.filterType) {
        URL += `&type=${this.filterType}`;
      }

      if (this.reasonFilter) {
        URL += `&reason=${this.reasonFilter}`;
      }

      this.urlFiltered = URL;
      this.menu = false;
      this.getUserLogError();
    },
    pageChangegedBySelect(page) {
      this.currentPage = page;
      this.getUserLogError();
    },
    goNextPage() {
      if (this.currentPage <= this.numberOfPages) this.currentPage++;
      this.getUserLogError();
    },
    backPreviousPage() {
      if (this.currentPage >= 1) this.currentPage--;
      this.getUserLogError();
    },
    async getUserLogError() {
      try {
        this.showInformations = false;
        let URL = `userlog/errors/${this.$route.params.id}?page=${this.currentPage}`;

        if (this.urlFiltered) URL += this.urlFiltered;

        const response = await this.http.get(URL);
        this.errorDetailsList = response.items;
        this.currentPage = response.page;
        this.numberOfPages = response.pages;
        this.showInformations = true;
      } catch (s) {
        this.$toast.error("Erro para trazer as informações");
        this.showInformations = true;
      }
    },
    generateRandomId() {
      const PART_1 = Math.floor(Math.random() * 245);
      const PART_2 = Date.now();
      return `${PART_1}${PART_2}`;
    },
    async downloadDocumentTemplates() {
      try {
        this.loadingDownloads = true;

        let URL = `userlog/exportErrors/${this.$route.params.id}?page=${this.currentPage}`;

        if (this.urlFiltered) URL += this.urlFiltered;

        const response = await this.http.get(URL);

        const anchor = document.createElement("a");
        anchor.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(response);
        anchor.target = "_blank";
        anchor.download = "userLogs.csv";
        anchor.click();

        this.loadingDownloads = false;
      } catch {
        this.loadingDownloads = false;
        this.$toast.error("Ocorreu um erro ao exportar os dados!");
      }
    },
    async getConfigurationList() {
      try {
        const URL = this.currentUrl ? this.currentUrl : "dbConfiguration/list";
        const req = await this.http.get(URL);
        this.recent = req;
        this.showInformations = true;
      } catch (error) {
        this.$toast.error("Erro ao listar configurações");
      }
    },
    async sortTable(nameToSort) {
      this.sortDesc = !this.sortDesc;
      try {
        const ORDER = this.sortDesc ? "ASC" : "DESC";
        const URL = `dbConfiguration/list?sort=${nameToSort}&order=${ORDER}`;
        this.currentUrl = URL;
        const response = await this.http.get(URL);
        if (response) {
          this.recent = response;
          if (this.searchConnection) {
            this.filtered = this.getConnectionsByName(this.searchConnection);
          }
        }
      } catch (error) {
        this.$toast.error("Erro ao ordenar dados");
      }
    },
    genericSortElements(arr, nameToSort) {
      return arr.sort((a, b) =>
        a[nameToSort] > b[nameToSort]
          ? 1
          : b[nameToSort] > a[nameToSort]
          ? -1
          : 0
      );
    },
    doSome() {},
    getConnectionsByName(name) {
      return this.recent.filter((item) =>
        item.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
      );
    },
    resetConnectionToDelete() {
      this.connectionToDelete = null;
    },
    resetConnectionToRename() {
      this.connectionToUpdate = null;
      this.dialogRename = false;
    },
    async confirmedDeleteConnection() {
      this.loadingDelete = true;
      try {
        const req = await this.http.delete(
          `dbConfiguration/${this.connectionToDelete.id}`
        );
        if (req) {
          await this.getConfigurationList();
          this.filtered = this.getConnectionsByName(this.searchConnection);
          this.$toast.success("Conexão apagada com sucesso!");
          this.resetConnectionToDelete();
          this.dialogDelete = false;
        }
        this.loadingDelete = false;
      } catch (error) {
        this.$toast.error("Erro ao deletar conexão");
        this.loadingDelete = false;
      }
    },
    async confirmUpdateNameConnection() {
      this.loadingRename = true;
      try {
        const req = await this.http.post(`dbConfiguration/rename`, {
          id: this.connectionToUpdate.id,
          name: this.connectionToUpdate.name,
        });
        if (req) {
          this.$toast.success("Conexão atualizada com sucesso!");
          this.getConfigurationList();
          this.loadingRename = false;
          this.resetConnectionToRename();
        }
        this.loadingRename = false;
        this.connectionToUpdate = null;
      } catch (error) {
        this.$toast.error("Erro ao renomear conexão");
        this.loadingRename = false;
      }
    },
    selectDotOption(data, optionData) {
      // TEM QUE VERIFICAR QUAL É O CAMPO PARA QUANDO É ATUALIZADO VIA API (data.cron vem como 'api')
      switch (optionData.value) {
        case 0:
          this.deleteConnection(data);
          break;
        case 1:
          this.updateConnection(data);
          break;
        case 2:
          this.$router.push({
            name: "docsIndexPage",
            params: { page: "primaryData", id: data.id },
          });
          break;
        case 3:
          this.$router.push({
            name: "logsIndexPage",
            params: { page: "primaryData", id: data.id },
          });
          break;
      }
    },
    selectOptionFromTable(data, option) {
      switch (option) {
        case 0:
          this.deleteConnection(data);
          break;
        case 1:
          this.updateConnection(data);
          break;
        case 2:
          this.$router.push({
            name: "docsIndexPage",
            params: { page: "primaryData", id: data.id },
          });
          break;
        case 3:
          this.$router.push({
            name: "logsIndexPage",
            params: { page: "primaryData", id: data.id },
          });
          break;
        case 4:
          this.$router.push({
            name: "editPrimaryConnectionPage",
            params: { id: data.id },
          });
          break;
      }
    },
    deleteConnection(connection) {
      this.connectionToDelete = connection;
      this.dialogDelete = true;
    },
    updateConnection(connection) {
      this.connectionToUpdate = { ...connection };
      this.dialogRename = true;
    },

    isImageLoaded() {
      // this.showInformations = isLoaded
    },
    setBadgeTheme(item) {
      const DEFAULT = {
        backgroundColor: "#d0294b",
        color: "#ffffff",
      };

      const STYLES = {
        updated: {
          backgroundColor: "#49c469",
          color: "#ffffff",
        },
        pending: {
          backgroundColor: "#FFC220",
          color: "#ffffff",
        },
        "in-progress": {
          backgroundColor: "#ff9320",
          color: "#ffffff",
        },
        error: { ...DEFAULT },
      };

      return STYLES[item] || DEFAULT;
    },
    goToPage(name) {
      this.$router.push({ name });
    },
    formateDate(date) {
      //moment(date).format("DD/MM/YYYY");
      return moment.utc(date).format("DD/MM/YYYY HH:mm");
    },

    async favorite(item) {
      let fav = item.favorite;
      if (fav == null || fav == undefined) {
        fav = false;
        try {
          await this.http.post("dbConfiguration/toggleFavorite", {
            id: item.id,
            favorite: fav,
          });

          this.getConfigurationList();
        } catch (error) {
          this.$toast.error("Erro ao realizar ação");
        }
      } else {
        fav = !fav;
        try {
          await this.http.post("dbConfiguration/toggleFavorite", {
            id: item.id,
            favorite: fav,
          });

          this.getConfigurationList();
        } catch (error) {
          this.$toast.error("Erro ao realizar ação");
        }
      }
    },
  },
};
</script>

<style>
.tableErrors .column {
  text-align: center !important;
}

.tableErrors td {
  text-align: center !important;
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: hidden;
    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-2;
      flex-wrap: wrap;
      .left {
        flex: 1 1 auto;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }
      .right {
        flex: 1 1 auto;
      }
      //   .right {
      //     flex: 2 1 600px;
      //     display: flex;
      //     justify-content: flex-end;
      //     align-items: center;
      //     flex-wrap: wrap;
      //     gap: $z-s-1 0.5rem;
      //     & > div {
      //       &:first-child {
      //         width: 40%;
      //       }
      //     }

      //     @media (max-width: 808px) {
      //       justify-content: flex-start;
      //       & > div {
      //         flex: 1 1 350px;
      //       }
      //     }
      //   }
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: $z-s-1;
      height: 90%;
      .table-wrapper {
        height: 500px !important;
        max-height: 550px;
        overflow-y: auto;
        @include trackScrollBar;

        .no-data-table {
          text-align: center;
        }

        @media (min-width: 1550px) {
          height: auto;
          max-height: 650px;
        }
      }

      @media (min-width: 720px) and (max-width: 1079px) {
        .table-wrapper {
          height: 400px;
          max-height: 400px;
        }
      }

      @media (max-width: 890px) {
        height: auto;
        .table-wrapper {
          height: 350px;
          max-height: 350px;
        }
      }

      // // NOVAS TABELAS ADICIONADAS
      @media (min-width: 750px) {
        ::v-deep .v-data-table {
          table {
            th {
              &:not(:first-child) {
                text-align: center !important;
              }
              &:last-child {
                text-align: right !important;
              }
            }
            td {
              &:not(:first-child) {
                text-align: center !important;
                & > div,
                span {
                  margin: 0 auto !important;
                }
              }
              &:last-child {
                text-align: right !important;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 850px) {
    height: auto;
  }

  .badge {
    padding: 0.03rem 0.25rem;
    border-radius: 50px;
    font-size: 0.75rem;
    font-weight: 500;
    transition: 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    max-width: 58%;

    @media (max-width: 650px) {
      max-width: 100%;
    }
    &:hover {
      // background: rgb(236, 236, 236);
    }
  }
}

.list-options {
  background: #ffffff;
  &__item {
    padding: 0.5rem;
    cursor: pointer;
    transition: 0.2s ease;
    span {
      font-weight: 500;
    }
    &:hover {
      background: #e2e2e2;
    }
  }
}

.delete-confirmation {
  padding: 1rem;
  &__title {
    text-align: center;
    font-weight: 500;
  }
  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}

.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
